import React, { useEffect, useState } from "react";

const CountdownTimer = () => {
  const calculateTimeLeft = () => {
    const targetDate = new Date("2025-01-01T00:00:00"); 
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="flex flex-col items-center bg-black text-white p-4 font-mono">
      <div className="flex flex-col items-center text-gray-400  text-3xl lg:text-6xl font-bold">
      <div className="flex space-x-4">
        <div className="pixelated mb-4">{String(timeLeft.days)}</div>
        <span className="text-white">:</span>
          <div className="pixelated">{String(timeLeft.hours).padStart(2, "0")}</div>
          <span className="text-white">:</span>
          <div className="pixelated">{String(timeLeft.minutes).padStart(2, "0")}</div>
          <span className="text-white">:</span>
          <div className="pixelated">{String(timeLeft.seconds).padStart(2, "0")}</div>
        </div>
      </div>
      <div className="flex  space-x-7 lg:space-x-20 mt-4 text-xl">
      <span>Days</span>
        <span>Hrs</span>
        <span>Min</span>
        <span>Sec</span>
      </div>
    </div>
  );
};

export default CountdownTimer;