import React, { useEffect, useState } from 'react';
import GradientButton2 from '../Style/GradientButton2';

export default function ProblemStatement() {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % 4);
        }, 7000);

        return () => clearInterval(timer);
    }, []);

    const handleDownload = (fileName) => {
        const link = document.createElement('a');
        link.href = `/assets/Doc/ProblemStatements.pdf`;
        link.download = `ProblemStatements.pdf`;
        link.click();
    };

    return (
        <div className='flex md:flex-row flex-col items-center gap-14 mt-5 pb-10'>
            <div className="md:w-1/2 w-full flex flex-col lg:flex-row md:justify-between gap-3 lg:gap-7 mt-7">
                {['Advance Computing', 'Blockchain', 'Metaverse', 'AI'].map((label, index) => (
                    <div
                        key={index}
                        className={`cursor-pointer group h-[10rem] lg:h-[27.5rem] flex items-center rounded-3xl shadow-md bg-center bg-no-repeat bg-cover group-hover:transition-transform duration-500 ease-in-out ${activeIndex === index
                            ? "lg:w-[60%] opacity-100 border-0 border-gray-900 h-[10rem] md:h-[18rem] lg:h-[27.5rem]"
                            : "lg:w-[20%] h-[2rem] lg:h-[27.5rem] border-0"
                            }`}
                        style={{ backgroundImage: `url('/assets/Others/PS${index + 1}.svg')` }}
                    // onClick={() => handleDownload(label.replace(' ', ''))}
                    >
                        <div className={`w-full ${activeIndex === index ? "" : "bg-black/70 h-full flex items-center"}`}>
                            <div
                                className={`duration-500 ${activeIndex === index ? "bg-black/70" : "lg:rotate-90"
                                    } text-center text-white text-sm md:text-lg font-bold py-2 md:py-5 w-full`}
                            >
                                {label}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='md:w-1/2 h-full flex flex-col justify-around items-center'>
                <div className='md:text-lg' >
                    <p><span className='font-semibold' >Secure identity management:</span> Protect people’s personal information.</p>
                    <p className='mt-5'><span className='font-semibold' >Data privacy:</span> Make sure data stays safe and private. Safe communication systems: Build platforms that are secure and trustworthy.</p>
                    <p className='mt-5'><span className='font-semibold' >Sustainable solutions:</span> Find ways to use tech to solve environmental or long-term societal issues.</p>
                    <p className='mt-5'><span className='font-semibold' >Creative content monetization:</span> Help creators earn from their work in fair and transparent ways.</p>
                </div>
                <div className='mt-10'>
                    <GradientButton2 text="Explore Problem Statements" onClick={() => handleDownload()} />
                </div>
            </div>
        </div>
    );
}
