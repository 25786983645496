import React from 'react'
import GradientButton from '../Style/GradientButton'

const Exclusivity = () => {
  return (
    <div className='flex flex-col justify-center items-center gap-14 py-10' >
      <div>
        <p className='text-3xl font-bold text-[#F29D38] text-center font-space' >D5art Exclusivity</p>
        <p className='w-[95%] md:w-[60%] mx-auto mt-5 text-[#BEC9DA] text-sm text-center' >Participate in our hackathon and unlock access to two exclusive D5art courses, designed to enhance your skills, validate your expertise, and prepare you for cutting-edge opportunities in the Web3 and tech industry</p>
      </div>
      <div>
        <img src='../assets/jordanevent/book.svg' className=' w-8/12 mx-auto ' />
      </div>
      <div className='flex'>
        <GradientButton text="Explore Now"/>
      </div>
    </div>
  )
}

export default Exclusivity