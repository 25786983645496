export const REACT_APP_SEEKER_SECRETKEY = "Seeker@sdfs23423@##%#$@$Y%USJNFJSWB##*$&@^23w54293y428935yjrj#$@$@#";
export const REACT_APP_RECRUITER_SECRETKEY = "Recruiter@20#31%456&$#234asd3485690567@@#$%#%#$@@*&^%$E$#$%#";
export const REACT_APP_MORALIS_APIKEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjgyOGY5ZWVkLTU5NWYtNDM4Yi1iNDE4LWI5YTA5NzRiZmRiMCIsIm9yZ0lkIjoiNDIxNDQ0IiwidXNlcklkIjoiNDMzNDIyIiwidHlwZUlkIjoiMzZjNjNhN2QtY2MwNi00OTdhLTk4YjQtMDY0NGUyYTkyNDNkIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MzQ1MzEwNzksImV4cCI6NDg5MDI5MTA3OX0.FQbR46w2MUE8mOEI8Ck74WUF9anWx2uV-wGOE8Tzz3U";

//export const REACT_APP_MORALIS_APIKEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjU2ODBkMGMyLWJkMjctNGMxMi05ZWZlLTEzMWVlNTFiNzJiMyIsIm9yZ0lkIjoiMzk2Mzg3IiwidXNlcklkIjoiNDA3MzE0IiwidHlwZUlkIjoiNWY5YjczOTAtMjQwNy00OGUwLWIwMWMtZDk1NTQxYTM3YWE1IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTgzNjkxNTUsImV4cCI6NDg3NDEyOTE1NX0.3eNhOC6kEhejAqDVXTjvo96WP-iJpTX4-wWwcD6j_TE";
//export const REACT_APP_MORALIS_APIKEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjVhOGQyZGMyLTI1NzUtNDdlMy1iNDZkLTMxNjkxNmZkNzk4NiIsIm9yZ0lkIjoiMzU2MDQ0IiwidXNlcklkIjoiMzY1OTM2IiwidHlwZUlkIjoiMDZjMGEyY2QtYzA5MC00NDIzLWFmMzEtMGQxZTkwOTIzNTZhIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2OTM5OTY4ODIsImV4cCI6NDg0OTc1Njg4Mn0.CEQ3GVp-OlaBrS2CYTI0ABr79w-oOcPhR36hXzwO3ec";

//"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImYwOTAzODlmLWE3ZjItNGZhZS1iNDAxLWE5YWI3YzA3NTBjMCIsIm9yZ0lkIjoiMzk0Mjc5IiwidXNlcklkIjoiNDA1MTQ0IiwidHlwZUlkIjoiZTQ4MjYxNjMtZTg4NS00NmU4LWEwNDEtYTFhMzhjN2Q0OTUxIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTcwNDcwMTgsImV4cCI6NDg3MjgwNzAxOH0.KMmhpeBrILB5HEOPd6oWS-1h2rgAMPrGsM659oyDnrE";
export const REACT_APP_MORALIS_CHAIN = "0x13882";
export const REACT_APP_MORALIS_NFT_ADDRESS = "0x497c3A77DC4604d91531f9667FC839131DA8e0a1";
// export const REACT_APP_MORALIS_NFT_ADDRESS = "0x202b138730602a87b69b89Ee9eA3E2ad48d8D7ec";
export const REACT_APP_MORALIS_TOKEN_ADDRESS = "0x1872a0d0143a3Da810ce77E4f5C32f8D02d856e8";

export const SERVER_URL = "https://edu.d5art.com";
// export const SERVER_URL = "https://ex.d5art.com";
export const SERVER_URL1 = "https://ex.d5art.com";



//  https://ex.d5art.com

//  https://ex.d5art.com

