// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
// import { NavLink, useNavigate } from "react-router-dom";
// import Header from "../../../Layout/Header";
// import Footer from "../../../Layout/Footer";
// import axios from 'axios';
// import { SERVER_URL } from '../../../../config';
// import toast, { Toaster } from "react-hot-toast";

// const TeamMembers = () => {
//     const navigate = useNavigate();
//     const [errors, setErrors] = useState({});
//     const [teamList, setTeamList] = useState([]);


//     const email = localStorage.getItem("edu_email");


//     useEffect(() => {
//         const fetch_teamMembersList = async () => {

//             try {
//                 const response = await axios.get(`${SERVER_URL}/api/fetch_teamMembersList`, {
//                     params: { email },
//                 });

//                 console.log("response",response)

//                 if (response.status === 200) {
//                     console.log('team members list:', response.data.data);
//                     setTeamList(response.data.data)
//                 } else {
//                     console.error('Unexpected response:', response);
//                 }
//             } catch (error) {
//                 console.error('Error fetching user step data:', error.response?.data || error.message);
//             }
//         };

//         fetch_teamMembersList();
//     }, []);






//     return (
//         <div className="w-full bg-Fabcbg h-screen pb-10 overflow-hidden overflow-y-auto bg-black bg-cover font-poppins text-white">
//             <Header />
//             <Toaster />
//             <div className="fade-in">
//                 <NavLink to='/jordan-event' > <button className='mx-[5%] text-white flex items-center'>
//                     <FontAwesomeIcon icon={faChevronLeft} className='text-sm mr-2' />
//                     Back
//                 </button>
//                 </NavLink>
//                 <div className="text-center">
//                     <h1
//                         style={{
//                             backgroundClip: "text",
//                             WebkitBackgroundClip: "text",
//                             color: "transparent",
//                             backgroundImage: "linear-gradient(to right, #31989B, #4A5CA4)",
//                         }}
//                         className=" text-2xl   font-semibold"
//                     >
//                         Events
//                     </h1>
//                     <p className="text-xl mt-3 font-semibold">Jordon Skill Summit</p>
//                     <p className=" text-xs italic mt-2">Dec 24 - Feb 25</p>
//                 </div>

//                 <div className="w-[90%] mx-auto mt-10 flex md:flex-row flex-col justify-between gap-10 md:gap-0">
//                     <div className="w-full md:w-[45%] ">
//                         <div className=" bg-[#D9D9D91C] rounded-2xl p-5 h-full">
//                             <p className="font-bold text-center">Team Name - {teamList.teamLeader?.teamName}</p>
//                             <div className=" flex flex-col justify-between h-full pb-5">
//                                 <ol className="mt-5 pl-5">
//                                     {teamList && teamList.teamLeader && (
//                                         <div className="mt-4">
//                                             <h3 className="font-semibold">Team Leader:</h3>
//                                             <div className="flex items-center gap-3">
//                                                 <p className="text-sm">
//                                                     {teamList.teamLeader.teamLeaderName} ({teamList.teamLeader.email})
//                                                 </p>
//                                                 <div className="w-2 h-2 rounded-full bg-green-600"></div>
//                                             </div>
//                                         </div>
//                                     )}

//                                     {teamList && teamList.teamMembers && teamList.teamMembers.length > 0 ? (
//                                         <ul>
//                                             <h3 className="font-semibold">Team Members:</h3>
//                                             {teamList.teamMembers.map((member, index) => (
//                                                 <li
//                                                     key={index}
//                                                     className="flex justify-between items-center"
//                                                 >
//                                                     <div className="flex items-center gap-3">
//                                                         <p className="text-sm">
//                                                             {member.teamMemberName} ({member.email})
//                                                         </p>
//                                                         <div className="w-2 h-2 rounded-full bg-green-600"></div>
//                                                     </div>
//                                                 </li>
//                                             ))}
//                                         </ul>
//                                     ) : (
//                                         <p>No team members found.</p>
//                                     )}
//                                 </ol>
//                                 <div
//                                     className={` flex  ${teamList.length > 0
//                                             ? " flex-row justify-between items-end"
//                                             : "flex-col-reverse justify-center items-center "
//                                         } `}
//                                 >
//                                     <div>
//                                         <div className="flex items-center gap-1 ">
//                                             <div className="w-2 h-2 rounded-full bg-green-600"></div>
//                                             <p className="text-xs italic">
//                                                 Pre-skill assessment completed
//                                             </p>
//                                         </div>
//                                         <div className="flex items-center gap-1 ">
//                                             <div className="w-2 h-2 rounded-full bg-yellow-600"></div>
//                                             <p className="text-xs italic">
//                                                 Yet to take Pre-skill assessment{" "}
//                                             </p>
//                                         </div>
//                                     </div>
//                                     <img src="/assets/Mascots/avatar.svg" className="w-5/12" />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <Footer />
//         </div>
//     );
// };

// export default TeamMembers;








// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronLeft, faXmark } from "@fortawesome/free-solid-svg-icons";
// import { NavLink } from "react-router-dom";
// import Header from "../../Seeker/LoginHeader";
// import Footer from "../../Footer";
// import axios from 'axios';
// import { SERVER_URL } from '../../Config';
// import toast, { Toaster } from "react-hot-toast";
// import { useSelector } from "react-redux";

// const TeamMembers = ({onClose}) => {
//     const [teamList, setTeamList] = useState({});
//     const [loading, setLoading] = useState(true);
//     const email = useSelector((state) => state.seeker_email);

//     useEffect(() => {
//         const fetch_teamMembersList = async () => {
//             setLoading(true);
//             try {
//                 const response = await axios.get(`${SERVER_URL}/api/fetch_teamMembersList`, {
//                     params: { email },
//                 });

//                 if (response.status === 200) {
//                     setTeamList(response.data.data);
//                 } else {
//                     console.error('Unexpected response:', response);
//                 }
//             } catch (error) {
//                 console.error('Error fetching user step data:', error.response?.data || error.message);
//             }
//             finally {
//                 setLoading(false); 
//             }
//         };

//         fetch_teamMembersList();
//     }, []);

//     const getStatusColor = (completed) => {
//         return completed ? "bg-green-600" : "bg-orange-600";
//     };

//     return (
//         <div className="w-[70%] mx-auto py-10 overflow-hidden overflow-y-auto 0 bg-cover font-poppins text-white">
//             <Toaster />
//             <div className="fade-in ">
//                 {/* <NavLink to='/jordan-event'> */}
                
//                 {/* </NavLink> */}
//                 {/* <div className="text-center">
//                     <h1
//                         style={{
//                             backgroundClip: "text",
//                             WebkitBackgroundClip: "text",
//                             color: "transparent",
//                             backgroundImage: "linear-gradient(to right, #31989B, #4A5CA4)",
//                         }}
//                         className=" text-2xl font-semibold"
//                     >
//                         Events
//                     </h1>
//                     <p className="text-xl mt-3 font-semibold text-[#F29D38]">Jordon Skill Summit</p>
//                      <p className=" text-xs italic mt-2">Dec 24 - Feb 25</p> 
//                 </div> */}

//                 <div className="w-full md:w-[60%] mx-auto">
//                 <div className="w-full flex justify-end " >
//                     <button onClick={() => onClose()} className=' text-white flex items-center'>
//                         <FontAwesomeIcon icon={faXmark} className='' />
//                     </button>
//                     </div>
//                     {loading ? (
                       
//                         <div className="flex justify-center items-center h-48">
//                             <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-orange-600"></div>
//                         </div>
//                     ) : (

//                     <div className="w-full md:w-[95%] mx-auto mt-5">
//                         <div className=" bg-[#D9D9D91C] rounded-2xl p-5 h-full">
                       
//                             <p className="font-bold text-center">Team Name - {teamList.teamLeader?.teamName}</p>
//                             <div className=" flex flex-col justify-between h-full pb-5">
//                                 <ol className="mt-5 pl-5">
//                                     {teamList.teamLeader && (
//                                         <div className="mt-4">
//                                             <h3 className="font-semibold text-[#F29D38] font-space">Team Leader:</h3>
//                                             <div className="flex items-center gap-3">
//                                                 <p className="text-sm">
//                                                     {teamList.teamLeader.teamLeaderName} ({teamList.teamLeader.email})
//                                                 </p>
//                                                 <div className={`w-2 h-2 rounded-full ${getStatusColor(teamList.teamLeader.completedAssessment)}`}></div>
//                                             </div>
//                                         </div>
//                                     )}

//                                     {teamList.teamMembers && teamList.teamMembers.length > 0 ? (
//                                         <ul className="mt-3" >
//                                             <h3 className="font-semibold text-[#F29D38] font-space ">Team Members:</h3>
//                                             {teamList.teamMembers.map((member, index) => (
//                                                 <li
//                                                     key={index}
//                                                     className="flex justify-between items-center mt-1"
//                                                 >
//                                                     <div className="flex items-center gap-3">
//                                                         <p className="text-sm">
//                                                             {member.teamMemberName} ({member.email})
//                                                         </p>
//                                                         <div className={`w-2 h-2 rounded-full ${getStatusColor(member.completedAssessment)}`}></div>
//                                                     </div>
//                                                 </li>
//                                             ))}
                                            


//                                         </ul>
//                                     ) : (
//                                         <p>No team members found.</p>
//                                     )}
//                                 </ol>
//                                 <div
//                                     className={`flex
//                                         flex-row justify-between items-end`}
//                                 >
//                                     <div>
//                                         <div className="flex items-center gap-1">
//                                             <div className="w-2 h-2 rounded-full bg-green-600"></div>
//                                             <p className="text-xs italic">
//                                                 Pre-skill assessment completed
//                                             </p>
//                                         </div>
//                                         <div className="flex items-center gap-1">
//                                             <div className="w-2 h-2 rounded-full bg-orange-600"></div>
//                                             <p className="text-xs italic">
//                                                 Yet to take Pre-skill assessment{" "}
//                                             </p>
//                                         </div>
//                                     </div>
//                                     <img src="/assets/Mascots/avatar.svg" className="w-5/12" />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                   )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default TeamMembers;




import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft,  faXmark, faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import Header from "../../Seeker/LoginHeader";
import Footer from "../../Footer";
import axios from 'axios';
import { SERVER_URL } from '../../Config';
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";

const TeamMembers = ({ onClose }) => {
    const [teamList, setTeamList] = useState({});
    const [loading, setLoading] = useState(true);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editMember, setEditMember] = useState(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [newMember, setNewMember] = useState({ teamMemberName: '', email: '', institutionName: '' });
    const [isTeamNameEdit, setIsTeamNameEdit] = useState(false);
    const [newTeamName, setNewTeamName] = useState('');
    const email = useSelector((state) => state.seeker_email);

    const fetchTeamMembersList = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${SERVER_URL}/api/fetch_teamMembersList`, {
                params: { email },
            });
            if (response.status === 200) {
                setTeamList(response.data.data);
            }
        } catch (error) {
            toast.error('Failed to fetch team details');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTeamMembersList();
    }, []);

    const handleEditMember = (member) => {
        setEditMember(member);
        setIsEditModalOpen(true);
    };

    const handleSaveEditMember = async () => {
        try {
            const response = await axios.post(`${SERVER_URL}/api/edit-team-member/${editMember.id}`, editMember);
            toast.success(response.data.message || 'Team member updated successfully');
            fetchTeamMembersList();
        } catch (error) {
            console.log("Error updating team member:", error);
            
            if (error.response && error.response.data && error.response.data.error) {
                const errorMessage = error.response.data.error;
                toast.error(errorMessage);
            } else {
                toast.error('Failed to update team member');
            }
        } finally {
            setIsEditModalOpen(false);
        }
    };
    

    const handleDeleteMember = async (id) => {
        if (!window.confirm('Are you sure you want to delete this team member?')) return;
        try {
            await axios.post(`${SERVER_URL}/api/delete-team-member/${id}`);
            toast.success('Team member deleted');
            fetchTeamMembersList();
        } catch (error) {
            toast.error('Failed to delete team member');
        }
    };

    const handleAddMember = async () => {
        if (!newMember.teamMemberName || !newMember.email || !newMember.institutionName) {
            toast.error('Please fill all the fields');
            return;
        }
    
        try {
            const response = await axios.post(`${SERVER_URL}/api/add-team-member`, {
                teamID: teamList.teamLeader.id,
                ...newMember,
            });
    
            toast.success(response.data.message || 'New member added successfully');
            fetchTeamMembersList();
        } catch (error) {
            console.error("Error adding team member:", error);
    
            // Check if the backend returned a specific error message
            if (error.response && error.response.data && error.response.data.error) {
                toast.error(error.response.data.error); // Show backend error message
            } else {
                toast.error('Failed to add team member'); // Generic error message
            }
        } finally {
            setIsAddModalOpen(false);
            setNewMember({ teamMemberName: '', email: '', institutionName: '' });
        }
    };
    

    const handleEditTeamName = async () => {
        if (!newTeamName) {
            toast.error('Team name cannot be empty');
            return;
        }
        try {
            await axios.post(`${SERVER_URL}/api/edit-team-name/${teamList.teamLeader.id}`, { teamName: newTeamName });
            toast.success('Team name updated');
            fetchTeamMembersList();
        } catch (error) {
            toast.error('Failed to update team name');
        } finally {
            setIsTeamNameEdit(false);
        }
    };

    const getStatusColor = (completed) => {
        return completed ? "bg-green-600" : "bg-orange-600";
    };

    const isTeamLeader = email === teamList?.teamLeader?.email;


    return (
        <div className="md:w-[70%] w-[95%] mx-auto py-10 overflow-hidden overflow-y-auto bg-cover font-poppins text-white">
            <Toaster />
            <div className="fade-in">
                <div className="w-full max-w-3xl flex justify-end">
                    <button onClick={onClose} className="text-white flex items-center">
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>
                {loading ? (
                    <div className="flex justify-center items-center h-48">
                        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-orange-600"></div>
                    </div>
                ) : (
                    <div className="w-full md:w-[95%] mx-auto mt-5">
                         <div className="flex flex-col justify-between bg-[#D9D9D91C] border border-white/30  overflow-hidden overflow-y-auto rounded-2xl p-5 md:p-10 w-full max-w-md max-h-md mx-auto md:h-[80vh]">
                         <div>
                        <div className="flex justify-between items-center">
                                {isTeamNameEdit ? (
                                    <div className="flex gap-2">
                                        <input
                                            type="text"
                                            className=" rounded  focus:outline-none bg-transparent  px-3 py-1 border-b  w-[80%] border-white/50  text-white"
                                            value={newTeamName}
                                            onChange={(e) => setNewTeamName(e.target.value)}
                                        />
                                      
                                        <button
                                            onClick={handleEditTeamName}
                                            className="px-3 py-1 rounded text-white"
                                        
                                        >
                                            <i class="fa-regular fa-circle-check fa-lg" style={{color:"#00ff00"}} ></i>
                                        </button>
                                      
                                    </div>
                                ) : (
                                    <p className="font-bold text-center text-2xl  w-full">
                                        <span className="text-transparent  font-bold bg-clip-text bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]">{teamList.teamLeader?.teamName}{' '}</span> 
                                        {isTeamLeader && (

                                        <button
                                            onClick={() => setIsTeamNameEdit(true)}
                                            className="ml-2 text-sm text-blue-400"
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                        )}
                                    </p>
                                )}
                            </div>

                         {teamList && teamList.teamLeader && (
                                        <div className="mt-4">
                                            <h3 className="font-semibold text-[#F29D38] font-space">Team Leader:</h3>
                                            <div className="flex mt-5 items-center gap-3">
                                                <p className="text-sm  md:text-left text-center ">
                                                    {teamList.teamLeader.teamLeaderName} <span >({teamList.teamLeader.email})</span> 
                                                </p>
                                                <div className={`w-2 h-2 rounded-full ${getStatusColor(teamList.teamLeader.completedAssessment)}`}></div>
                                            </div>
                                        </div>
                                    )}

                            <h3 className="font-semibold text-[#F29D38] font-space mt-4">
                                Team Members

                                {isTeamLeader && (

                                <button
                                    className="ml-2 text-green-400"
                                    onClick={() => setIsAddModalOpen(true)}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            )}
                            </h3>
                            <ul className="mt-3">


                                { teamList && teamList.teamMembers.length > 0 ? (
                                teamList && teamList.teamMembers.map((member, index) => (
                                    <li
                                        key={index}
                                        className="flex justify-between items-center mt-1"
                                    >
                                        <div className="flex items-center gap-3 mt-3 ">
                                            <p className=" text-xs font-medium md:text-sm">
                                                {member.teamMemberName} ({member.email})
                                            </p>
                                            <div
                                                className={`w-2 h-2 rounded-full ${getStatusColor(member.completedAssessment)}`}
                                            ></div>
                                        </div>
                                        {isTeamLeader && (

                                        <div className="flex gap-2">
                                            <button
                                                onClick={() => handleEditMember(member)}
                                                className="text-blue-400"
                                            >
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                            <button
                                                onClick={() => handleDeleteMember(member.id)}
                                                className="text-red-400"
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </div>
                                        )}
                                    </li>
                                ))
                            ) : (
                                <li className="mt-3 text-center  text-gray-400 italic">No data available</li>
                            ) }
                            </ul>


                            {isAddModalOpen && (
                                <div className="modal mt-10">
                                    <div className="modal-content">
                                    <h2 className="font-semibold text-[#F29D38] font-space mt-4">
                                    Add Team Member</h2>
                                        <div className="mt-5 ">
                                        <input
                                            type="text"
                                            placeholder="Name"
                                            className="bg-transparent w-full px-3 py-1 text-sm focus:outline-none border-b border-white/30  text-white"
                                            value={newMember.teamMemberName}
                                            onChange={(e) => setNewMember({ ...newMember, teamMemberName: e.target.value })}
                                        />
                                        </div>
                                        <div className="mt-5 ">
                                        <input
                                            type="email"
                                            placeholder="Email"
                                            className="bg-transparent w-full px-3 py-1 text-sm focus:outline-none border-b border-white/30  text-white"
                                            value={newMember.email}
                                            onChange={(e) => setNewMember({ ...newMember, email: e.target.value })}
                                        />
                                      </div>
                                      <div className="mt-5 ">
                                      <input
                                            type="text"
                                            placeholder="Institution Name"
                                            className="bg-transparent w-full px-3 py-1 text-sm focus:outline-none border-b border-white/30  text-white"
                                            value={newMember.institutionName}
                                            onChange={(e) => setNewMember({ ...newMember, institutionName: e.target.value })}
                                        />
                                      </div>
                                      <div className="mt-5 flex justify-center gap-10 items-center "> 
                                        <button className="bg-gray-500 text-sm font-semibold px-5 py-1.5 rounded-full hover:bg-gray-600 "   onClick={handleAddMember}>Add</button>
                                        
                                        <button className="bg-gray-500 text-sm font-semibold px-5 py-1.5 rounded-full  hover:bg-gray-600  " onClick={() => setIsAddModalOpen(false)}>Cancel</button>
                                        </div>
                                      
                                    </div>
                                </div>
                            )}

                    
                            {isEditModalOpen && (
                                <div className="modal">
                                    <div className="modal-content">
                                    <h2 className="font-semibold text-[#F29D38] font-space mt-4">Edit Team Member</h2>
                                        <div className="mt-5 ">
                                        <input
                                            type="text"
                                            value={editMember.teamMemberName}
                                            className="bg-transparent w-full px-3 py-1 text-sm focus:outline-none border-b border-white/30  text-white"
                                            onChange={(e) => setEditMember({ ...editMember, teamMemberName: e.target.value })}
                                        />
                                      </div>
                                      <div className="mt-5 ">
                                      <input
                                            type="email"
                                            className="bg-transparent w-full px-3 py-1 text-sm focus:outline-none border-b border-white/30  text-white"
                                            value={editMember.email}
                                            onChange={(e) => setEditMember({ ...editMember, email: e.target.value })}
                                        />
                                        </div>
                                        <div className="mt-5 ">
                                        <input
                                            type="text"
                                            className="bg-transparent w-full px-3 py-1 text-sm focus:outline-none border-b border-white/30  text-white"

                                            value={editMember.institutionName}
                                            onChange={(e) => setEditMember({ ...editMember, institutionName: e.target.value })}
                                        />
                                       </div>
                                       <div className="mt-5 flex justify-center gap-10 items-center "> 

                                        <button  className="bg-gray-500 text-sm font-semibold px-5 py-1.5 rounded-full hover:bg-gray-600  "  onClick={handleSaveEditMember}>Save</button>
                                      
                                        <button   className="bg-gray-500 text-sm font-semibold px-5 py-1.5 rounded-full hover:bg-gray-600  "  onClick={() => setIsEditModalOpen(false)}>Cancel</button>
                                    </div>
                                     </div>
                                   
                                </div>
                            )}



                        </div>

                           <div>
                        <div className=" flex justify-between items-end mt-10">
                            <div>
                            <div className="flex items-center gap-1 ">
                                <div className="w-2 h-2 rounded-full bg-green-600"></div>
                                <p className="text-xs italic">
                                    Pre-skill assessment completed
                                </p>
                            </div>
                            <div className="flex items-center gap-1 ">
                                <div className="w-2 h-2 rounded-full bg-yellow-600"></div>
                                <p className="text-xs italic">
                                    Yet to take Pre-skill assessment{" "}
                                </p>
                            </div>
                        </div>

                        {teamList.teamMembers?.length < 3 && (
           <img src="/assets/Mascots/avatar.svg" className="w-5/12" />
                    )}

                       </div>

                            
                        </div>
                          
                        </div> 
                       

                    </div>
                )}
            </div>

        
        </div>
    );
};

export default TeamMembers;