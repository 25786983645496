import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../../Seeker/LoginHeader";
import Footer from "../../Footer";
import axios from 'axios';
import { SERVER_URL } from '../../Config';
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";

const AddTeamMembers = ({ completedStep2 }) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [teamList, setTeamList] = useState([]);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

 
  const email = useSelector((state) => state.seeker_email);


  const [formData, setFormData] = useState({
    membername: "",
    institutionName: "",
    email: "",
  });


  const [hasToastShown, setHasToastShown] = useState(false); 

  useEffect(() => {
    const checkUserRegistration = async () => {
      if (hasToastShown) return;

      try {
        const response = await axios.get(`${SERVER_URL}/api/user-role-check`, {
          params: { email },
        });
        toast.dismiss();

        if (response.data.userType === "teamLeader") {
          toast.success("Welcome, Team Leader!");
          setHasToastShown(true); 
        } else if (response.data.userType === "teamMember") {
          toast.error("You are a team member!");
          setTimeout(() => {
            navigate("/jordan-event");
          }, 2000);
          setHasToastShown(true); 
        }
      } catch (error) {
        toast.dismiss();

        if (error.response?.status === 404) {
          toast.error("Team leaders only add members");
          setTimeout(() => {
            navigate("/jordan-event");
          }, 2000);
        } else {
          toast.error("Error checking registration");
        }
      }
    };

    if (email) {
      checkUserRegistration();
    } else {
      toast.error("Email is not available.");
      navigate("/jordan-event");
    }
  }, [email, navigate, hasToastShown]); 


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validate = () => {
    let formErrors = {};
    if (!formData.membername.trim()) {
      formErrors.membername = "Member Name is required.";
    }
    if (!formData.institutionName.trim()) {
      formErrors.institutionName = "College Name is required.";
    }
    if (!formData.email.trim()) {
      formErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid.";
    }
    return formErrors;
  };

  const handleAdd = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0 && teamList.length < 5) {
      const updatedList = [...teamList, formData];
      setTeamList(updatedList);
      setFormData({ membername: "", email: "", institutionName: "" });
      setErrors({});

      if (updatedList.length === 1) {
        setIsSubmitEnabled(true);
      }
    } else {
      setErrors(validationErrors);
      if (teamList.length >= 5) {
        toast.error("You can only add up to 5 team members.");
      }
    }
  };

  const handleRemove = (index) => {
    const updatedList = teamList.filter((_, i) => i !== index);
    setTeamList(updatedList);
    if (updatedList.length < 1) {
      setIsSubmitEnabled(false);
    }

  if (updatedList.length < 5) {
    toast.success("You can add more members.");
  }

  };

    
  const handleSubmitTeamList = async () => {
    console.log("Team List:", teamList);
  
    const formattedTeamList = teamList.map(member => ({
      teamMemberName: member.membername,
      email: member.email,
      institutionName: member.institutionName,
    }));
  
    try {
      const response = await axios.post(
        `${SERVER_URL}/api/teamMembersList`,
        { teamList: formattedTeamList, teamLeaderEmail: email },
        { headers: { "Content-Type": "application/json" } }
      );
  
      if (response.status === 200) {
        if (response.data.duplicates && response.data.duplicates.length > 0) {
          toast.error("Some duplicates were skipped:");
          response.data.duplicates.forEach(duplicate => {
            toast.error(`Duplicate member: ${duplicate.teamMemberName} (${duplicate.email})`);
          });
          return;
        }
  
        toast.success(response.data.message);
          setTimeout(() => {
          window.location.href= "/jordan-event/instruction"
        }, 2000);
      }
    } catch (error) {
      console.error("Error submitting the form:", error.message);
      if (error.response?.data?.error) {
        const errorMessage = error.response.data.error;
        const unregisteredMembers = error.response.data.unregisteredMembers;
        if (unregisteredMembers) {
          toast.error(`${errorMessage}: ${unregisteredMembers.map(member => `${member.teamMemberName} (${member.email})`).join(', ')}`);
        } else {
          toast.error(errorMessage);
        }
      } else {
        toast.error("An error occurred while submitting the form.");
      }
    }
  };
  
  
  
  
  


  return (
    <div className="w-full bg-Fabcbg h-screen pb-10 overflow-hidden overflow-y-auto bg-black bg-cover font-poppins text-white">
      <Header/>
      <Toaster/>
      <div className="fade-in">
      <NavLink to='/jordan-event' > <button className='mx-[5%] text-white flex items-center'>
            <FontAwesomeIcon icon={faChevronLeft} className='text-sm mr-2'/>
            Back
            </button>
            </NavLink>
        <div className="text-center">
          <h1
            style={{
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
              backgroundImage: "linear-gradient(to right, #31989B, #4A5CA4)",
            }}
            className=" text-2xl   font-semibold"
          >
            Events
          </h1>
          <p className="text-xl mt-3 font-semibold">Jordon Skill Summit</p>
          <p className=" text-xs italic mt-2">Dec 24 - Feb 25</p>
        </div>

        <div className="w-[90%] mx-auto mt-10 flex md:flex-row flex-col justify-between gap-10 md:gap-0">
          <div className="w-full md:w-[45%] mb-10">
            <div className=" bg-[#D9D9D91C] rounded-2xl p-5 h-full">
              <p className="font-bold text-center">Team</p>
              <div className=" flex flex-col justify-between h-full pb-5">
                <ol className="mt-5 pl-5">
                  {teamList.map((member, index) => (
                    <li
                      key={index}
                      className="flex justify-between items-center"
                    >
                      <div className="flex items-center gap-3">
                        <p className="text-sm">
                          {member.membername} ({member.email})
                        </p>
                        <div className="w-2 h-2 rounded-full bg-yellow-600"></div>
                      </div>
                      <button
                        onClick={() => handleRemove(index)}
                        className="text-red-500 text-xs italic font-medium ml-3"
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ol>
                <div
                  className={` flex  ${
                    teamList.length > 0
                      ? " flex-row justify-between items-end"
                      : "flex-col-reverse justify-center items-center "
                  } `}
                >
                  <div>
                    <div className="flex items-center gap-1 ">
                      <div className="w-2 h-2 rounded-full bg-green-600"></div>
                      <p className="text-xs italic">
                        Pre-skill assessment completed
                      </p>
                    </div>
                    <div className="flex items-center gap-1 ">
                      <div className="w-2 h-2 rounded-full bg-yellow-600"></div>
                      <p className="text-xs italic">
                        Yet to take Pre-skill assessment{" "}
                      </p>
                    </div>
                  </div>
                  <img src="/assets/Mascots/avatar.svg" className="w-5/12" />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-[40%] mb-10">
            <p className="font-semibold text-lg text-center">
              Ready To Onboard
            </p>
            <div className="flex items-center justify-between mt-5">
              <div className="flex flex-col items-center">
                <p className="text-2xl text-[#02DC52] font-bold">1</p>
                <p className="text-xs text-[#02DC52]">Register</p>
              </div>
              <div className="flex flex-col items-center">
                <p className="text-2xl font-bold">2</p>
                <p className="text-xs">Pre-Skill</p>
              </div>
              <div className="flex flex-col items-center">
                <p className="text-2xl font-bold">3</p>
                <p className="text-xs">Pitch Submission</p>
              </div>
            </div>
            <div className="w-[80%] mx-auto">
              <div className="mt-5">
                <label className="text-sm">Team Member Name</label>
                <input
                  name="membername"
                  value={formData.membername}
                  onChange={handleChange}
                  className="w-full bg-[#D9D9D9] text-black text-sm rounded-lg py-2 px-4 focus:outline-none border border-black/5"
                  placeholder="Enter Team Member Name"
                />
                {errors.membername && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.membername}
                  </p>
                )}
              </div>
              <div className="mt-5">
                <label className="text-sm">Mail ID</label>
                <input
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full bg-[#D9D9D9] text-black text-sm rounded-lg py-2 px-4 focus:outline-none border border-black/5"
                  placeholder="Enter Email Id"
                />
                {errors.email && (
                  <p className="text-red-500 text-xs mt-1">{errors.email}</p>
                )}
              </div>
              <div className="mt-5">
                <label className="text-sm">Institution Name</label>
                <input
                  name="institutionName"
                  value={formData.institutionName}
                  onChange={handleChange}
                  className="w-full bg-[#D9D9D9] text-black text-sm rounded-lg py-2 px-4 focus:outline-none border border-black/5"
                  placeholder="Enter Institution Name"
                />
                {errors.institutionName && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.institutionName}
                  </p>
                )}
              </div>
              <div className="flex justify-center items-center mt-5 gap-5">
                <button
                  onClick={handleAdd}
                  className={`border-2 border-[#5973CC] rounded-lg px-5 py-1.5 font-medium text-sm ${
                    teamList.length === 5 ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={teamList.length === 5}
                >
                  Add
                </button>
                <button
                  onClick={handleSubmitTeamList}
                  className={`bg-[#5973CC] rounded-lg px-5 py-2 font-medium text-sm ${
                    isSubmitEnabled ? "" : "opacity-50 cursor-not-allowed"
                  }`}
                  disabled={!isSubmitEnabled}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default AddTeamMembers;
