// SkillSlider Component
import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

export default function SkillSlider({ skills }) {
    const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  
    const chunkArray = (array, chunkSize) => {
      const result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
      }
      return result;
    };
  
    const allSkills = Array.isArray(skills)
      ? skills.flatMap((skillString) => {
          try {
            return JSON.parse(skillString) || [];
          } catch (error) {
            console.error("Error parsing skill:", error);
            return [];
          }
        })
      : [];
  
    const skillGroups = chunkArray(allSkills, 3);
  
    const handlePrevious = () => {
      setCurrentGroupIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : skillGroups.length - 1
      );
    };
  
    const handleNext = () => {
      setCurrentGroupIndex((prevIndex) =>
        prevIndex < skillGroups.length - 1 ? prevIndex + 1 : 0
      );
    };
  
    if (skillGroups.length === 0) {
      return (
        <div className="h-[132px] flex justify-center items-center">
          <p>No skills available</p>
        </div>
      );
    }
  
    const currentSkills = skillGroups[currentGroupIndex];
  
    const filledSkills = [...currentSkills];
    while (filledSkills.length < 3) {
      filledSkills.push("");
    }
  
    return (
      <div className="relative w-[100%]">
        <div className="skill-display grid w-[75%] mx-auto ">
          {filledSkills.map((skill, skillIndex) => (
            <div key={`${currentGroupIndex}-${skillIndex}`} className="mb-2">
              <p
                className={`px-3 py-2 text-sm text-center truncate rounded-full ${
                  skill ? "bg-white/20" : "bg-transparent"
                } uppercase`}
              >
                {skill.length > 9
                  ? `${skill.substring(0, 9)}...`
                  : skill || "\u00A0"}
              </p>
            </div>
          ))}
        </div>
  
        {skillGroups.length > 1 && (
          <div className="flex justify-between w-full">
            <FaChevronLeft
              onClick={handlePrevious}
              className="absolute cursor-pointer text-yellow-500 -translate-y-[50%] top-[50%] left-0 md:-left-5 lg:-left-1"
              size={24}
            />
            <FaChevronRight
              onClick={handleNext}
              className="absolute cursor-pointer text-yellow-500 -translate-y-[50%] top-[50%] right-0 md:-right-5 lg:-right-1"
              size={24}
            />
          </div>
        )}
      </div>
    );
  }
  