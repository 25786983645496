import React from 'react';

function GradientButton({ text, onClick }) {
    return (
        <button className='relative flex items-center justify-center' onClick={onClick}>
            <svg width="242" height="65" viewBox="0 0 242 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_289_133)">
                    <path d="M225 8H17C13.134 8 10 11.134 10 15V49C10 52.866 13.134 56 17 56H210.596C212.859 56 215.055 55.2324 216.826 53.8227L228.229 44.7418C230.612 42.8445 232 39.9647 232 36.919V15C232 11.134 228.866 8 225 8Z" fill="url(#paint0_linear_289_133)" />
                    <path d="M225 8H17C13.134 8 10 11.134 10 15V49C10 52.866 13.134 56 17 56H210.596C212.859 56 215.055 55.2324 216.826 53.8227L228.229 44.7418C230.612 42.8445 232 39.9647 232 36.919V15C232 11.134 228.866 8 225 8Z" stroke="#EFF1F6" stroke-opacity="0.05" />
                    <path d="M234 1H8C4.13401 1 1 4.13401 1 8V57C1 60.866 4.13401 64 8 64H217.596C220.189 64 222.682 62.9923 224.546 61.1895L237.951 48.2307C239.9 46.3465 241 43.7519 241 41.0412V8C241 4.13401 237.866 1 234 1Z" stroke="white" stroke-opacity="0.1" />
                </g>
                <defs>
                    <linearGradient id="paint0_linear_289_133" x1="10" y1="11.8571" x2="328.693" y2="85.2009" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" stop-opacity="0.25" />
                        <stop offset="1" stop-color="white" stop-opacity="0.1" />
                    </linearGradient>
                    <clipPath id="clip0_289_133">
                        <rect width="242" height="65" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            <h1 className='absolute'>{text}</h1>
        </button>
    );
}

export default GradientButton;
