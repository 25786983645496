import React from 'react'

export default function GradientButton2({ text, onClick }) {
    return (
        <button className='relative flex items-center justify-center' onClick={onClick}>
            <svg width="262" height="65" viewBox="0 0 262 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M243.401 8H17.5991C13.4022 8 10 11.134 10 15V49C10 52.866 13.4022 56 17.5991 56H227.764C230.221 56 232.605 55.2324 234.527 53.8227L246.906 44.7418C249.493 42.8445 251 39.9647 251 36.919V15C251 11.134 247.598 8 243.401 8Z" fill="url(#paint0_linear_290_135)" />
                <path d="M243.401 8H17.5991C13.4022 8 10 11.134 10 15V49C10 52.866 13.4022 56 17.5991 56H227.764C230.221 56 232.605 55.2324 234.527 53.8227L246.906 44.7418C249.493 42.8445 251 39.9647 251 36.919V15C251 11.134 247.598 8 243.401 8Z" stroke="#EFF1F6" stroke-opacity="0.05" />
                <path d="M253.417 1H8.58333C4.39518 1 1 4.13401 1 8V57C1 60.866 4.39518 64 8.58333 64H235.646C238.455 64 241.156 62.9923 243.175 61.1895L257.697 48.2307C259.808 46.3465 261 43.7519 261 41.0412V8C261 4.13401 257.605 1 253.417 1Z" stroke="white" stroke-opacity="0.1" />
                <defs>
                    <linearGradient id="paint0_linear_290_135" x1="10" y1="11.8571" x2="352.89" y2="97.5233" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" stop-opacity="0.25" />
                        <stop offset="1" stop-color="white" stop-opacity="0.1" />
                    </linearGradient>
                </defs>
            </svg>
            <h1 className='absolute'>{text}</h1>
        </button>
    )
}
