// src/pages/SyncEmailPage.jsx
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setRecruiterEmail, setSeekerEmail, setValidatorEmail } from "./store";
// import { setEmail } from "../redux/userSlice";

const SyncEmailPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    const role = params.get("role");
    const redirect = params.get("redirect");
    const returnURL = params.get("return");

    console.log("email", email<"returnURL",returnURL,"redirect",redirect)

    if (email) {

      switch (role) {
        case 'seeker':
          dispatch(setSeekerEmail(email));
          break;
        case 'recruiter':
          dispatch(setRecruiterEmail(email));
          break;
        case 'validator':
          dispatch(setValidatorEmail(email));
          break;
        default:
          dispatch(setSeekerEmail(email));
      }
     


      // if (redirect) {
      //   window.location.href = redirect;
      // } else if (returnURL) {
      //   window.location.href = returnURL;
      // }
      if (returnURL) {
        window.location.replace(returnURL); // Fast redirect
      } else if (redirect) {
        window.location.replace(redirect); // Fast redirect
      }
    }
  }, [dispatch]);

  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots === '...') {
          return '';
        } else {
          return prevDots + '.';
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);


  return (
    <div className='flex items-center justify-center h-screen bg-black'>
            <div>
                <div className='h-[8rem] w-[12.5rem]'>
                <img src='/assets/profile_loader.gif' className='w-[75%] mx-auto'></img>
                </div>
                <p className='text-center font-bricolagegrotesque text-sky-300'>Loading {dots}</p>
            </div>
        </div>
  );
};

export default SyncEmailPage;
